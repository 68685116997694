<template>
  <v-container>
    <div v-if="approvedOrders.length > 0" class="ml-2">
      <PageTitle
        text="List Of Approved Lube Requests"
        class=" font font-weight-medium font-size-md"
      />
    </div>
    <v-row v-if="isLoading">
      <v-col cols="12" sm="12" align="center">
        <v-progress-circular
          indeterminate
          size="30"
          width="4"
          color="primary"
        />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-if="approvedOrders.length > 0 && !isLoading"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="approvedOrders"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="isLoading"
              class="box-shadow-light"
            >
              <template #item.avatar="{item}">
                <v-avatar color="blue" class="ma-1" size="40">
                  <span v-if="item.attendantId" class="white--text">{{
                    item.attendantId.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.lubesOrdersLineItem="{item}">
                <v-chip small label class="font-weight-bold">{{
                  item.lubesOrdersLineItem.length
                }}</v-chip>
              </template>
              <template #item.isOrderReviewed="{item}">
                <v-chip
                  v-if="item.isOrderReviewed"
                  label
                  :color="item.isOrderReviewed ? '#9efb9e66' : '#f98c6b'"
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderReviewed
                        ? 'rgba(3, 91, 3, 0.83)'
                        : '#862c00'
                    }"
                    class="font-weight-bold"
                    >Reviewed</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  label
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span class="font-weight-bold">{{ "Not Reviewed " }}</span>
                </v-chip>
              </template>
              <template #item.isOrderApproved="{item}">
                <v-chip
                  label
                  :color="
                    item.isOrderApproved ? '#9efb9e66' : 'rgb(247, 189, 172)'
                  "
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderApproved
                        ? 'rgba(3, 91, 3, 0.83)'
                        : 'rgb(238, 78, 0)'
                    }"
                    class="font-weight-bold"
                    >Approved</span
                  >
                </v-chip>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      dark
                      v-on="on"
                      small
                      :to="{
                        name: 'request.deliveries.lubes.details',
                        params: { id: item.id }
                      }"
                    >
                      <v-icon small color="black">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Confirm Deliveries</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center" style="min-height: 500px;">
      <div class="d-flex flex-column flex-grow-1 " style="align-items:center;">
        <img src="@/assets/pallets.svg" style="width: 180px;" class="mb-3" />
        <span class="font font-weight-medium font-size-md pt-3"
          >No approved <kbd class="ml-1">lube order requests</kbd> available
          right now.</span
        >
        <span class="font font-weight-medium font-size-sm pt-2"
          >All approved requests will show up here !!!</span
        >
      </div>
    </v-row>

    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import PageTitle from "../../components/PageTitle";
import moment from "moment";

export default {
  name: "ListLubeOrders",
  components: {
    PageTitle,
    SnackBar: () => import(`../../components/SnackBar`)
  },
  filters: {
    format(value) {
      return moment(value).format("Do MMMM, YYYY");
    }
  },
  data() {
    return {
      approvedOrders: [],
      showSnackBar: false,
      message: "",
      status: "",
      isLoading: false,
      headers: [
        {
          text: "Date",
          value: "date"
        },
        {
          text: "Order Reference ",
          value: "orderReference"
        },
        {
          text: "Order Line Items",
          value: "lubesOrdersLineItem"
        },
        {
          text: "Reviewed Status",
          value: "isOrderReviewed"
        },
        {
          text: "Approval Status",
          value: "isOrderApproved"
        },
        {
          text: "Actions",
          value: "actions"
        }
      ],
      paginate: {
        page: 1,
        limit: 20,
        total: 0
      }
    };
  },
  methods: {
    async listApprovedLubeRequest() {
      try {
        this.isLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/orders/lubes/approved/requests?isPaginated=true&page=${this.paginate.page}&limit=${this.paginate.limit}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.isLoading = false;
          const { page, pages, limit } = response.data.paginateObj;
          this.approvedOrders = response.data.paginateObj.docs;
          this.paginate = { page, limit, total: pages };
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list for review";
        this.status = "red";
        this.isLoading = false;
      }
    }
  },
  created() {
    this.listApprovedLubeRequest();
  }
};
</script>
